import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import buyerMasterEntryForm from './buyerMasterEntryForm';
import purchaseReqVset from '../purchaseRequisition/purchaseReqVset';

export default {
  name: 'buyerMaster',
  watch: {
    currentPage: function() {
      this.getBuyMasDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBuyMasDtls();
    }
  },
  components: { DatePicker, buyerMasterEntryForm, purchaseReqVset },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      buyerName: {
        text: null,
        value: null
      },
      costCenter: null,
      buyerMasterData: [],
      buyerMasterFields: [
        {
          key: 'item_code',
          label: 'Item',
          class: 'text-center'
        },
        {
          key: 'description',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'buyer_name',
          label: 'Buyer Name',
          class: 'text-center'
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          class: 'text-center'
        },
        {
          key: 'location',
          label: 'Loaction',
          class: 'text-center'
        },
        {
          key: 'region',
          label: 'Region',
          class: 'text-center'
        },
        {
          key: 'creation_date',
          label: 'Creation Date',
          class: 'text-center'
        }
      ],
      showBuyMastEntryModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      unsubscribe: null,
      showValueSetModal: false,
      rowData: null,
      openModalFormType: 'buyerMaster',
      openModalType: null,
      vsetName: null,
      showPurReqVsetModal: false,
      reqKeyFilter: {},
      creationDateFrom: null,
      creationDateTo: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showBuyMastEntryModal = true;
        }
      }
    });
  },
  methods: {
    showHideBuyMastEntryModal(flag) {
      this.showBuyMastEntryModal = flag;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.creationDateFrom);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.status = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.buyerName.value) {
        this.buyerName = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.costCenter) {
        this.costCenter = null;
      }
    },
    getBuyMasDtls() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        buyer_name: this.buyerName.text,
        cost_center: this.costCenter,
        creation_date_from: this.creationDateFrom,
        creation_date_to: this.creationDateTo
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getBuyMasDtls', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.buyerMasterData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.buyerName = {
        text: null,
        value: null
      };
      this.costCenter = null;
      this.totalRows = null;
      this.buyerMasterData = [];
    },
    openVsetModal(type) {
      if (type === 'buyer') {
        this.vsetName = 'Buyer Details';
        this.openModalType = 'buyer';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'costCenter') {
        this.vsetName = 'Cost Center Details';
        this.openModalType = 'costCenter';
        this.showHidePurReqVsetModal(true);
      }
    },
    showHidePurReqVsetModal(flag) {
      this.showPurReqVsetModal = flag;
    },
    mapSelectedBuyerVsetData(item) {
      if (item.name === 'buyMasBuyer') {
        this.buyerName = {
          text: item.global_name,
          value: item.agent_id
        };
      } else if (item.name === 'buyMasCostCenter') {
        this.costCenter = item.flex_value;
      }
      this.showPurReqVsetModal = false;
    },
    rowSelected(item) {
      this.rowData = item;
      this.showHideBuyMastEntryModal(true);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
